// @flow
import {
    PAGE_TYPE_INTERNAL,
    PAGE_TYPE_EXTERNAL,
    BACKGROUND_TYPE_IMAGE,
    BACKGROUND_TYPE_VIDEO,
    BACKGROUND_TYPE_SOLID_COLOR
} from '../config/consts';

//      *** GRID IMAGES ***      //
import mamut_grid_s from '../images/work/grid/mamut_grid_s.jpg';
import mamut_grid_xl from '../images/work/grid/mamut_grid_xl.jpg';

import brave_zone_grid_s from '../images/work/grid/brave_zone_grid_s.jpg';
import brave_zone_grid_xl from '../images/work/grid/brave_zone_grid_xl.jpg';

import lieferheld_grid_s from '../images/work/grid/lieferheld_grid_s.jpg';
import lieferheld_grid_xl from '../images/work/grid/lieferheld_grid_xl.jpg';

import iam_grid_s from '../images/work/grid/iam_grid_s.jpg';
import iam_grid_xl from '../images/work/grid/iam_grid_xl.jpg';

import icaew_grid_s from '../images/work/grid/icaew_grid_s.jpg';
import icaew_grid_xl from '../images/work/grid/icaew_grid_xl.jpg';

import lemontbotanique_grid_s from '../images/work/grid/lemontbotanique_grid_s.jpg';
import lemontbotanique_grid_xl from '../images/work/grid/lemontbotanique_grid_xl.jpg';

import raiffeisen_grid_s from '../images/work/grid/raiffeisen_grid_s.jpg';
import raiffeisen_grid_xl from '../images/work/grid/raiffeisen_grid_xl.jpg';

import zukunf_lauft_grid_s from '../images/work/grid/zukunf_lauft_grid_s.jpg';
import zukunf_lauft_grid_xl from '../images/work/grid/zukunf_lauft_grid_xl.jpg';

import supper_apps_grid_s from '../images/work/grid/supper_apps_grid_s.jpg';
import supper_apps_grid_xl from '../images/work/grid/supper_apps_grid_xl.jpg';

import ally_grid_s from '../images/work/grid/ally_grid_s.jpg';
import ally_grid_xl from '../images/work/grid/ally_grid_xl.jpg';

import mr_mrs_smith_grid_s from '../images/work/grid/mr_mrs_smith_grid_s.jpg';
import mr_mrs_smith_grid_xl from '../images/work/grid/mr_mrs_smith_grid_xl.jpg';

import bubble_nova_grid_s from '../images/work/grid/bubble_nova_grid_s.jpg';
import bubble_nova_grid_xl from '../images/work/grid/bubble_nova_grid_xl.jpg';

import calconvert_grid_s from '../images/work/grid/calconvert_grid_s.jpg';
import calconvert_grid_xl from '../images/work/grid/calconvert_grid_xl.jpg';

import timer_grid_s from '../images/work/grid/timer_grid_s.jpg';
import timer_grid_xl from '../images/work/grid/timer_grid_xl.jpg';

import spooky_hill_grid_s from '../images/work/grid/spooky_hill_grid_s.jpg';
import spooky_hill_grid_xl from '../images/work/grid/spooky_hill_grid_xl.jpg';

import gothenburg_grid_s from '../images/work/grid/gothenburg_grid_s.jpg';
import gothenburg_grid_xl from '../images/work/grid/gothenburg_grid_xl.jpg';

import affligem_grid_s from '../images/work/grid/affligem_grid_s.jpg';
import affligem_grid_xl from '../images/work/grid/affligem_grid_xl.jpg';

import msf_grid_s from '../images/work/grid/msf_grid_s.jpg';
import msf_grid_xl from '../images/work/grid/msf_grid_xl.jpg';

import gasag_grid_s from '../images/work/grid/gasag_grid_s.jpg';
import gasag_grid_xl from '../images/work/grid/gasag_grid_xl.jpg';

import massup_grid_s from '../images/work/grid/massup_grid_s.jpg';
import massup_grid_xl from '../images/work/grid/massup_grid_xl.jpg';

import hmd_grid_s from '../images/work/grid/hmd_grid_s.jpg';
import hmd_grid_xl from '../images/work/grid/hmd_grid_xl.jpg';

import siili_grid_s from '../images/work/grid/siili_grid_s.jpg';
import siili_grid_xl from '../images/work/grid/siili_grid_xl.jpg';

import opentalk_grid_s from '../images/work/grid/opentalk_grid_s.jpg';
import opentalk_grid_xl from '../images/work/grid/opentalk_grid_xl.jpg';

import fitogram_grid_s from '../images/work/grid/fitogram_grid_s.jpg';
import fitogram_grid_xl from '../images/work/grid/fitogram_grid_xl.jpg';

import quieton_grid_s from '../images/work/grid/quieton_grid_s.jpg';
import quieton_grid_xl from '../images/work/grid/quieton_grid_xl.jpg';



//      *** LOGO IMAGES ***      //
import mamut_logo from '../images/work/logos/mamut_logo.svg';
import icaew_logo from '../images/work/logos/icaew_logo.svg';
import raiffeisen_logo from '../images/work/logos/raiffeisen_logo.svg';
import brave_zone_logo from '../images/work/logos/brave_zone_logo.svg';
import bubble_nova_logo from '../images/work/logos/bubble_nova_logo.svg';
import lieferheld_logo from '../images/work/logos/lieferheld_logo.svg';
import lemontbotanique_logo from '../images/work/logos/lemontbotanique_logo.svg';
import supper_apps_logo from '../images/work/logos/supper_apps_logo.svg';
import zukunf_lauft_logo from '../images/work/logos/zukunf_lauft_logo.svg';
import iam_logo from '../images/work/logos/iam_logo.svg';
import ally_logo from '../images/work/logos/ally_logo.svg';
import calconvert_logo from '../images/work/logos/calconvert_logo.svg';
import affligem_logo from '../images/work/logos/affligem_logo.svg';
import gothenburg_logo from '../images/work/logos/gothenburg_logo.svg';
import spooky_hill_logo from '../images/work/logos/spooky_hill_logo.svg';
import mr_mrs_smith_logo from '../images/work/logos/mr_mrs_smith_logo.svg';
import timer_logo from '../images/work/logos/timer_logo.svg';
import msf_logo from '../images/work/logos/msf_logo.svg';
import gasag_logo from '../images/work/logos/gasag_logo.svg';
import massup_logo from '../images/work/logos/massup_logo.svg';
import hmd_logo from '../images/work/logos/hmd_logo.svg';
import siili_logo from '../images/work/logos/siili_logo.svg';
import opentalk_logo from '../images/work/logos/opentalk_logo.svg';
import fitogram_logo from '../images/work/logos/fitogram_logo.svg';
import quieton_logo from '../images/work/logos/quieton_logo.png';


//      *** BACKGROUND IMAGES FOR FIRST SLIDE ***      //
import mamut_background_xl1 from '../images/work/backgrounds/mamut_background_xl1.jpg';
import mamut_background_l1 from '../images/work/backgrounds/mamut_background_l1.jpg';
import mamut_background_m1 from '../images/work/backgrounds/mamut_background_m1.jpg';
import mamut_background_s1 from '../images/work/backgrounds/mamut_background_s1.jpg';
import icaew_background_xl1 from '../images/work/backgrounds/icaew_background_xl1.jpg';
import icaew_background_l1 from '../images/work/backgrounds/icaew_background_l1.jpg';
import icaew_background_m1 from '../images/work/backgrounds/icaew_background_m1.jpg';
import icaew_background_s1 from '../images/work/backgrounds/icaew_background_s1.jpg';
import raiffeisen_background_xl1 from '../images/work/backgrounds/raiffeisen_background_xl1.jpg';
import raiffeisen_background_l1 from '../images/work/backgrounds/raiffeisen_background_l1.jpg';
import raiffeisen_background_m1 from '../images/work/backgrounds/raiffeisen_background_m1.jpg';
import raiffeisen_background_s1 from '../images/work/backgrounds/raiffeisen_background_s1.jpg';
import brave_zone_background_xl1 from '../images/work/backgrounds/brave_zone_background_xl1.jpg';
import brave_zone_background_l1 from '../images/work/backgrounds/brave_zone_background_l1.jpg';
import brave_zone_background_m1 from '../images/work/backgrounds/brave_zone_background_m1.jpg';
import brave_zone_background_s1 from '../images/work/backgrounds/brave_zone_background_s1.jpg';
import bubble_nova_background_xl1 from '../images/work/backgrounds/bubble_nova_background_xl1.jpg';
import bubble_nova_background_l1 from '../images/work/backgrounds/bubble_nova_background_l1.jpg';
import bubble_nova_background_m1 from '../images/work/backgrounds/bubble_nova_background_m1.jpg';
import bubble_nova_background_s1 from '../images/work/backgrounds/bubble_nova_background_s1.jpg';
import lieferheld_background_xl1 from '../images/work/backgrounds/lieferheld_background_xl1.jpg';
import lieferheld_background_l1 from '../images/work/backgrounds/lieferheld_background_l1.jpg';
import lieferheld_background_m1 from '../images/work/backgrounds/lieferheld_background_m1.jpg';
import lieferheld_background_s1 from '../images/work/backgrounds/lieferheld_background_s1.jpg';
import lemontbotanique_background_xl1 from '../images/work/backgrounds/lemontbotanique_background_xl1.jpg';
import lemontbotanique_background_l1 from '../images/work/backgrounds/lemontbotanique_background_l1.jpg';
import lemontbotanique_background_m1 from '../images/work/backgrounds/lemontbotanique_background_m1.jpg';
import lemontbotanique_background_s1 from '../images/work/backgrounds/lemontbotanique_background_s1.jpg';
import supper_apps_background_xl1 from '../images/work/backgrounds/supper_apps_background_xl1.jpg';
import supper_apps_background_l1 from '../images/work/backgrounds/supper_apps_background_l1.jpg';
import supper_apps_background_m1 from '../images/work/backgrounds/supper_apps_background_m1.jpg';
import supper_apps_background_s1 from '../images/work/backgrounds/supper_apps_background_s1.jpg';
import zukunf_lauft_background_xl1 from '../images/work/backgrounds/zukunf_lauft_background_xl1.jpg';
import zukunf_lauft_background_l1 from '../images/work/backgrounds/zukunf_lauft_background_l1.jpg';
import zukunf_lauft_background_m1 from '../images/work/backgrounds/zukunf_lauft_background_m1.jpg';
import zukunf_lauft_background_s1 from '../images/work/backgrounds/zukunf_lauft_background_s1.jpg';
import iam_background_xl1 from '../images/work/backgrounds/iam_background_xl1.jpg';
import iam_background_l1 from '../images/work/backgrounds/iam_background_l1.jpg';
import iam_background_m1 from '../images/work/backgrounds/iam_background_m1.jpg';
import iam_background_s1 from '../images/work/backgrounds/iam_background_s1.jpg';
import ally_background_xl1 from '../images/work/backgrounds/ally_background_xl1.jpg';
import ally_background_l1 from '../images/work/backgrounds/ally_background_l1.jpg';
import ally_background_m1 from '../images/work/backgrounds/ally_background_m1.jpg';
import ally_background_s1 from '../images/work/backgrounds/ally_background_s1.jpg';
import calconvert_background_xl1 from '../images/work/backgrounds/calconvert_background_xl1.jpg';
import calconvert_background_l1 from '../images/work/backgrounds/calconvert_background_l1.jpg';
import calconvert_background_m1 from '../images/work/backgrounds/calconvert_background_m1.jpg';
import calconvert_background_s1 from '../images/work/backgrounds/calconvert_background_s1.jpg';
import affligem_background_xl1 from '../images/work/backgrounds/affligem_background_xl1.jpg';
import affligem_background_l1 from '../images/work/backgrounds/affligem_background_l1.jpg';
import affligem_background_m1 from '../images/work/backgrounds/affligem_background_m1.jpg';
import affligem_background_s1 from '../images/work/backgrounds/affligem_background_s1.jpg';
import gothenburg_background_xl1 from '../images/work/backgrounds/gothenburg_background_xl1.jpg';
import gothenburg_background_l1 from '../images/work/backgrounds/gothenburg_background_l1.jpg';
import gothenburg_background_s1 from '../images/work/backgrounds/gothenburg_background_s1.jpg';
import gothenburg_background_m1 from '../images/work/backgrounds/gothenburg_background_m1.jpg';
import spooky_hill_background_xl1 from '../images/work/backgrounds/spooky_hill_background_xl1.jpg';
import spooky_hill_background_l1 from '../images/work/backgrounds/spooky_hill_background_l1.jpg';
import spooky_hill_background_m1 from '../images/work/backgrounds/spooky_hill_background_m1.jpg';
import spooky_hill_background_s1 from '../images/work/backgrounds/spooky_hill_background_s1.jpg';
import mr_mrs_smith_background_xl1 from '../images/work/backgrounds/mr_mrs_smith_background_xl1.jpg';
import mr_mrs_smith_background_l1 from '../images/work/backgrounds/mr_mrs_smith_background_l1.jpg';
import mr_mrs_smith_background_m1 from '../images/work/backgrounds/mr_mrs_smith_background_m1.jpg';
import mr_mrs_smith_background_s1 from '../images/work/backgrounds/mr_mrs_smith_background_s1.jpg';
import timer_background_xl1 from '../images/work/backgrounds/timer_background_xl1.jpg';
import timer_background_l1 from '../images/work/backgrounds/timer_background_l1.jpg';
import timer_background_m1 from '../images/work/backgrounds/timer_background_m1.jpg';
import timer_background_s1 from '../images/work/backgrounds/timer_background_s1.jpg';
import msf_background_xl1 from '../images/work/backgrounds/msf_background_xl1.jpg';
import msf_background_l1 from '../images/work/backgrounds/msf_background_l1.jpg';
import msf_background_m1 from '../images/work/backgrounds/msf_background_m1.jpg';
import msf_background_s1 from '../images/work/backgrounds/msf_background_s1.jpg';
import gasag_background_xl1 from '../images/work/backgrounds/gasag_background_xl1.jpg';
import gasag_background_l1 from '../images/work/backgrounds/gasag_background_l1.jpg';
import gasag_background_m1 from '../images/work/backgrounds/gasag_background_m1.jpg';
import gasag_background_s1 from '../images/work/backgrounds/gasag_background_s1.jpg';
import massup_background_xl1 from '../images/work/backgrounds/massup_background_xl1.jpg';
import massup_background_l1 from '../images/work/backgrounds/massup_background_l1.jpg';
import massup_background_m1 from '../images/work/backgrounds/massup_background_m1.jpg';
import massup_background_s1 from '../images/work/backgrounds/massup_background_s1.jpg';
import hmd_background_xl1 from '../images/work/backgrounds/hmd_background_xl1.jpg';
import hmd_background_l1 from '../images/work/backgrounds/hmd_background_l1.jpg';
import hmd_background_m1 from '../images/work/backgrounds/hmd_background_m1.jpg';
import hmd_background_s1 from '../images/work/backgrounds/hmd_background_s1.jpg';
import siili_background_xl1 from '../images/work/backgrounds/siili_background_xl1.jpg';
import siili_background_l1 from '../images/work/backgrounds/siili_background_l1.jpg';
import siili_background_m1 from '../images/work/backgrounds/siili_background_m1.jpg';
import siili_background_s1 from '../images/work/backgrounds/siili_background_s1.jpg';
import opentalk_background_xl1 from '../images/work/backgrounds/opentalk_background_xl1.jpg';
import opentalk_background_l1 from '../images/work/backgrounds/opentalk_background_l1.jpg';
import opentalk_background_m1 from '../images/work/backgrounds/opentalk_background_m1.jpg';
import opentalk_background_s1 from '../images/work/backgrounds/opentalk_background_s1.jpg';
import fitogram_background_xl1 from '../images/work/backgrounds/fitogram_background_xl1.jpg';
import fitogram_background_l1 from '../images/work/backgrounds/fitogram_background_l1.jpg';
import fitogram_background_m1 from '../images/work/backgrounds/fitogram_background_m1.jpg';
import fitogram_background_s1 from '../images/work/backgrounds/fitogram_background_s1.jpg';
import quieton_background_xl1 from '../images/work/backgrounds/quieton_background_xl1.jpg';
import quieton_background_l1 from '../images/work/backgrounds/quieton_background_l1.jpg';
import quieton_background_m1 from '../images/work/backgrounds/quieton_background_m1.jpg';
import quieton_background_s1 from '../images/work/backgrounds/quieton_background_s1.jpg';

//      *** BACKGROUND IMAGES FOR 2nd SLIDE ***      //
import mamut_background_xl2 from '../images/work/backgrounds/mamut_background_xl2.jpg';
import mamut_background_l2 from '../images/work/backgrounds/mamut_background_l2.jpg';
import mamut_background_m2 from '../images/work/backgrounds/mamut_background_m2.jpg';
import mamut_background_s2 from '../images/work/backgrounds/mamut_background_s2.jpg';
import icaew_background_xl2 from '../images/work/backgrounds/icaew_background_xl2.jpg';
import icaew_background_l2 from '../images/work/backgrounds/icaew_background_l2.jpg';
import icaew_background_m2 from '../images/work/backgrounds/icaew_background_m2.jpg';
import icaew_background_s2 from '../images/work/backgrounds/icaew_background_s2.jpg';
import raiffeisen_background_xl2 from '../images/work/backgrounds/raiffeisen_background_xl2.jpg';
import raiffeisen_background_l2 from '../images/work/backgrounds/raiffeisen_background_l2.jpg';
import raiffeisen_background_m2 from '../images/work/backgrounds/raiffeisen_background_m2.jpg';
import raiffeisen_background_s2 from '../images/work/backgrounds/raiffeisen_background_s2.jpg';
import brave_zone_background_xl2 from '../images/work/backgrounds/brave_zone_background_xl2.jpg';
import brave_zone_background_l2 from '../images/work/backgrounds/brave_zone_background_l2.jpg';
import brave_zone_background_m2 from '../images/work/backgrounds/brave_zone_background_m2.jpg';
import brave_zone_background_s2 from '../images/work/backgrounds/brave_zone_background_s2.jpg';
import bubble_nova_background_xl2 from '../images/work/backgrounds/bubble_nova_background_xl2.jpg';
import bubble_nova_background_l2 from '../images/work/backgrounds/bubble_nova_background_l2.jpg';
import bubble_nova_background_m2 from '../images/work/backgrounds/bubble_nova_background_m2.jpg';
import bubble_nova_background_s2 from '../images/work/backgrounds/bubble_nova_background_s2.jpg';
import lieferheld_background_xl2 from '../images/work/backgrounds/lieferheld_background_xl2.jpg';
import lieferheld_background_l2 from '../images/work/backgrounds/lieferheld_background_l2.jpg';
import lieferheld_background_m2 from '../images/work/backgrounds/lieferheld_background_m2.jpg';
import lieferheld_background_s2 from '../images/work/backgrounds/lieferheld_background_s2.jpg';
import lemontbotanique_background_xl2 from '../images/work/backgrounds/lemontbotanique_background_xl2.jpg';
import lemontbotanique_background_l2 from '../images/work/backgrounds/lemontbotanique_background_l2.jpg';
import lemontbotanique_background_m2 from '../images/work/backgrounds/lemontbotanique_background_m2.jpg';
import lemontbotanique_background_s2 from '../images/work/backgrounds/lemontbotanique_background_s2.jpg';
import supper_apps_background_xl2 from '../images/work/backgrounds/supper_apps_background_xl2.jpg';
import supper_apps_background_l2 from '../images/work/backgrounds/supper_apps_background_l2.jpg';
import supper_apps_background_m2 from '../images/work/backgrounds/supper_apps_background_m2.jpg';
import supper_apps_background_s2 from '../images/work/backgrounds/supper_apps_background_s2.jpg';
import zukunf_lauft_background_xl2 from '../images/work/backgrounds/zukunf_lauft_background_xl2.jpg';
import zukunf_lauft_background_l2 from '../images/work/backgrounds/zukunf_lauft_background_l2.jpg';
import zukunf_lauft_background_m2 from '../images/work/backgrounds/zukunf_lauft_background_m2.jpg';
import zukunf_lauft_background_s2 from '../images/work/backgrounds/zukunf_lauft_background_s2.jpg';
import iam_background_xl2 from '../images/work/backgrounds/iam_background_xl2.jpg';
import iam_background_l2 from '../images/work/backgrounds/iam_background_l2.jpg';
import iam_background_m2 from '../images/work/backgrounds/iam_background_m2.jpg';
import iam_background_s2 from '../images/work/backgrounds/iam_background_s2.jpg';
import ally_background_xl2 from '../images/work/backgrounds/ally_background_xl2.jpg';
import ally_background_l2 from '../images/work/backgrounds/ally_background_l2.jpg';
import ally_background_m2 from '../images/work/backgrounds/ally_background_m2.jpg';
import ally_background_s2 from '../images/work/backgrounds/ally_background_s2.jpg';
import calconvert_background_xl2 from '../images/work/backgrounds/calconvert_background_xl2.jpg';
import calconvert_background_l2 from '../images/work/backgrounds/calconvert_background_l2.jpg';
import calconvert_background_m2 from '../images/work/backgrounds/calconvert_background_m2.jpg';
import calconvert_background_s2 from '../images/work/backgrounds/calconvert_background_s2.jpg';
import affligem_background_xl2 from '../images/work/backgrounds/affligem_background_xl2.jpg';
import affligem_background_l2 from '../images/work/backgrounds/affligem_background_l2.jpg';
import affligem_background_m2 from '../images/work/backgrounds/affligem_background_m2.jpg';
import affligem_background_s2 from '../images/work/backgrounds/affligem_background_s2.jpg';
import gothenburg_background_xl2 from '../images/work/backgrounds/gothenburg_background_xl2.jpg';
import gothenburg_background_l2 from '../images/work/backgrounds/gothenburg_background_l2.jpg';
import gothenburg_background_m2 from '../images/work/backgrounds/gothenburg_background_m2.jpg';
import gothenburg_background_s2 from '../images/work/backgrounds/gothenburg_background_s2.jpg';
import spooky_hill_background_xl2 from '../images/work/backgrounds/spooky_hill_background_xl2.jpg';
import spooky_hill_background_l2 from '../images/work/backgrounds/spooky_hill_background_l2.jpg';
import spooky_hill_background_m2 from '../images/work/backgrounds/spooky_hill_background_m2.jpg';
import spooky_hill_background_s2 from '../images/work/backgrounds/spooky_hill_background_s2.jpg';
import mr_mrs_smith_background_xl2 from '../images/work/backgrounds/mr_mrs_smith_background_xl2.jpg';
import mr_mrs_smith_background_l2 from '../images/work/backgrounds/mr_mrs_smith_background_l2.jpg';
import mr_mrs_smith_background_m2 from '../images/work/backgrounds/mr_mrs_smith_background_m2.jpg';
import mr_mrs_smith_background_s2 from '../images/work/backgrounds/mr_mrs_smith_background_s2.jpg';
import timer_background_xl2 from '../images/work/backgrounds/timer_background_xl2.jpg';
import timer_background_l2 from '../images/work/backgrounds/timer_background_l2.jpg';
import timer_background_m2 from '../images/work/backgrounds/timer_background_m2.jpg';
import timer_background_s2 from '../images/work/backgrounds/timer_background_s2.jpg';
import msf_background_xl2 from '../images/work/backgrounds/msf_background_xl2.jpg';
import msf_background_l2 from '../images/work/backgrounds/msf_background_l2.jpg';
import msf_background_m2 from '../images/work/backgrounds/msf_background_m2.jpg';
import msf_background_s2 from '../images/work/backgrounds/msf_background_s2.jpg';
import gasag_background_xl2 from '../images/work/backgrounds/gasag_background_xl2.jpg';
import gasag_background_l2 from '../images/work/backgrounds/gasag_background_l2.jpg';
import gasag_background_m2 from '../images/work/backgrounds/gasag_background_m2.jpg';
import gasag_background_s2 from '../images/work/backgrounds/gasag_background_s2.jpg';
import massup_background_xl2 from '../images/work/backgrounds/massup_background_xl2.jpg';
import massup_background_l2 from '../images/work/backgrounds/massup_background_l2.jpg';
import massup_background_m2 from '../images/work/backgrounds/massup_background_m2.jpg';
import massup_background_s2 from '../images/work/backgrounds/massup_background_s2.jpg';
import hmd_background_xl2 from '../images/work/backgrounds/hmd_background_xl2.jpg';
import hmd_background_l2 from '../images/work/backgrounds/hmd_background_l2.jpg';
import hmd_background_m2 from '../images/work/backgrounds/hmd_background_m2.jpg';
import hmd_background_s2 from '../images/work/backgrounds/hmd_background_s2.jpg';
import siili_background_xl2 from '../images/work/backgrounds/siili_background_xl2.jpg';
import siili_background_l2 from '../images/work/backgrounds/siili_background_l2.jpg';
import siili_background_m2 from '../images/work/backgrounds/siili_background_m2.jpg';
import siili_background_s2 from '../images/work/backgrounds/siili_background_s2.jpg';
import opentalk_background_xl2 from '../images/work/backgrounds/opentalk_background_xl2.jpg';
import opentalk_background_l2 from '../images/work/backgrounds/opentalk_background_l2.jpg';
import opentalk_background_m2 from '../images/work/backgrounds/opentalk_background_m2.jpg';
import opentalk_background_s2 from '../images/work/backgrounds/opentalk_background_s2.jpg';
import fitogram_background_xl2 from '../images/work/backgrounds/fitogram_background_xl2.jpg';
import fitogram_background_l2 from '../images/work/backgrounds/fitogram_background_l2.jpg';
import fitogram_background_m2 from '../images/work/backgrounds/fitogram_background_m2.jpg';
import fitogram_background_s2 from '../images/work/backgrounds/fitogram_background_s2.jpg';
import quieton_background_xl2 from '../images/work/backgrounds/quieton_background_xl2.jpg';
import quieton_background_l2 from '../images/work/backgrounds/quieton_background_l2.jpg';
import quieton_background_m2 from '../images/work/backgrounds/quieton_background_m2.jpg';
import quieton_background_s2 from '../images/work/backgrounds/quieton_background_s2.jpg';


//      *** IMAGES FOR SLIDE-SHOW - 3rd SLIDE ***      //
import ally_slideshow_xl1 from '../images/work/slideshow/ally_xl1.png';
import ally_slideshow_xl2 from '../images/work/slideshow/ally_xl2.png';
import ally_slideshow_xl3 from '../images/work/slideshow/ally_xl3.png';
import ally_slideshow_l1 from '../images/work/slideshow/ally_l1.png';
import ally_slideshow_l2 from '../images/work/slideshow/ally_l2.png';
import ally_slideshow_l3 from '../images/work/slideshow/ally_l3.png';
import ally_slideshow_m1 from '../images/work/slideshow/ally_m1.png';
import ally_slideshow_m2 from '../images/work/slideshow/ally_m2.png';
import ally_slideshow_m3 from '../images/work/slideshow/ally_m3.png';
import ally_slideshow_s1 from '../images/work/slideshow/ally_s1.png';
import ally_slideshow_s2 from '../images/work/slideshow/ally_s2.png';
import ally_slideshow_s3 from '../images/work/slideshow/ally_s3.png';

import mamut_slideshow_xl1 from '../images/work/slideshow/mamut_xl1.png';
import mamut_slideshow_xl2 from '../images/work/slideshow/mamut_xl2.png';
import mamut_slideshow_xl3 from '../images/work/slideshow/mamut_xl3.png';
import mamut_slideshow_l1 from '../images/work/slideshow/mamut_l1.png';
import mamut_slideshow_l2 from '../images/work/slideshow/mamut_l2.png';
import mamut_slideshow_l3 from '../images/work/slideshow/mamut_l3.png';
import mamut_slideshow_m1 from '../images/work/slideshow/mamut_m1.png';
import mamut_slideshow_m2 from '../images/work/slideshow/mamut_m2.png';
import mamut_slideshow_m3 from '../images/work/slideshow/mamut_m3.png';
import mamut_slideshow_s1 from '../images/work/slideshow/mamut_s1.png';
import mamut_slideshow_s2 from '../images/work/slideshow/mamut_s2.png';
import mamut_slideshow_s3 from '../images/work/slideshow/mamut_s3.png';

import icaew_slideshow_xl1 from '../images/work/slideshow/icaew_xl1.png';
import icaew_slideshow_xl2 from '../images/work/slideshow/icaew_xl2.png';
import icaew_slideshow_xl3 from '../images/work/slideshow/icaew_xl3.png';
import icaew_slideshow_l1 from '../images/work/slideshow/icaew_l1.png';
import icaew_slideshow_l2 from '../images/work/slideshow/icaew_l2.png';
import icaew_slideshow_l3 from '../images/work/slideshow/icaew_l3.png';
import icaew_slideshow_m1 from '../images/work/slideshow/icaew_m1.png';
import icaew_slideshow_m2 from '../images/work/slideshow/icaew_m2.png';
import icaew_slideshow_m3 from '../images/work/slideshow/icaew_m3.png';
import icaew_slideshow_s1 from '../images/work/slideshow/icaew_s1.png';
import icaew_slideshow_s2 from '../images/work/slideshow/icaew_s2.png';
import icaew_slideshow_s3 from '../images/work/slideshow/icaew_s3.png';

import raiffeisen_slideshow_xl1 from '../images/work/slideshow/raiffeisen_xl1.png';
import raiffeisen_slideshow_xl2 from '../images/work/slideshow/raiffeisen_xl2.png';
import raiffeisen_slideshow_xl3 from '../images/work/slideshow/raiffeisen_xl3.png';
import raiffeisen_slideshow_l1 from '../images/work/slideshow/raiffeisen_l1.png';
import raiffeisen_slideshow_l2 from '../images/work/slideshow/raiffeisen_l2.png';
import raiffeisen_slideshow_l3 from '../images/work/slideshow/raiffeisen_l3.png';
import raiffeisen_slideshow_m1 from '../images/work/slideshow/raiffeisen_m1.png';
import raiffeisen_slideshow_m2 from '../images/work/slideshow/raiffeisen_m2.png';
import raiffeisen_slideshow_m3 from '../images/work/slideshow/raiffeisen_m3.png';
import raiffeisen_slideshow_s1 from '../images/work/slideshow/raiffeisen_s1.png';
import raiffeisen_slideshow_s2 from '../images/work/slideshow/raiffeisen_s2.png';
import raiffeisen_slideshow_s3 from '../images/work/slideshow/raiffeisen_s3.png';

import brave_zone_slideshow_xl1 from '../images/work/slideshow/brave_zone_xl1.png';
import brave_zone_slideshow_xl2 from '../images/work/slideshow/brave_zone_xl2.png';
import brave_zone_slideshow_xl3 from '../images/work/slideshow/brave_zone_xl3.png';
import brave_zone_slideshow_l1 from '../images/work/slideshow/brave_zone_l1.png';
import brave_zone_slideshow_l2 from '../images/work/slideshow/brave_zone_l2.png';
import brave_zone_slideshow_l3 from '../images/work/slideshow/brave_zone_l3.png';
import brave_zone_slideshow_m1 from '../images/work/slideshow/brave_zone_m1.png';
import brave_zone_slideshow_m2 from '../images/work/slideshow/brave_zone_m2.png';
import brave_zone_slideshow_m3 from '../images/work/slideshow/brave_zone_m3.png';
import brave_zone_slideshow_s1 from '../images/work/slideshow/brave_zone_s1.png';
import brave_zone_slideshow_s2 from '../images/work/slideshow/brave_zone_s2.png';
import brave_zone_slideshow_s3 from '../images/work/slideshow/brave_zone_s3.png';

import bubble_nova_slideshow_xl1 from '../images/work/slideshow/bubble_nova_xl1.png';
import bubble_nova_slideshow_xl2 from '../images/work/slideshow/bubble_nova_xl2.png';
import bubble_nova_slideshow_xl3 from '../images/work/slideshow/bubble_nova_xl3.png';
import bubble_nova_slideshow_l1 from '../images/work/slideshow/bubble_nova_l1.png';
import bubble_nova_slideshow_l2 from '../images/work/slideshow/bubble_nova_l2.png';
import bubble_nova_slideshow_l3 from '../images/work/slideshow/bubble_nova_l3.png';
import bubble_nova_slideshow_m1 from '../images/work/slideshow/bubble_nova_m1.png';
import bubble_nova_slideshow_m2 from '../images/work/slideshow/bubble_nova_m2.png';
import bubble_nova_slideshow_m3 from '../images/work/slideshow/bubble_nova_m3.png';
import bubble_nova_slideshow_s1 from '../images/work/slideshow/bubble_nova_s1.png';
import bubble_nova_slideshow_s2 from '../images/work/slideshow/bubble_nova_s2.png';
import bubble_nova_slideshow_s3 from '../images/work/slideshow/bubble_nova_s3.png';

import lieferheld_slideshow_xl1 from '../images/work/slideshow/lieferheld_xl1.png';
import lieferheld_slideshow_xl2 from '../images/work/slideshow/lieferheld_xl2.png';
import lieferheld_slideshow_xl3 from '../images/work/slideshow/lieferheld_xl3.png';
import lieferheld_slideshow_l1 from '../images/work/slideshow/lieferheld_l1.png';
import lieferheld_slideshow_l2 from '../images/work/slideshow/lieferheld_l2.png';
import lieferheld_slideshow_l3 from '../images/work/slideshow/lieferheld_l3.png';
import lieferheld_slideshow_m1 from '../images/work/slideshow/lieferheld_m1.png';
import lieferheld_slideshow_m2 from '../images/work/slideshow/lieferheld_m2.png';
import lieferheld_slideshow_m3 from '../images/work/slideshow/lieferheld_m3.png';
import lieferheld_slideshow_s1 from '../images/work/slideshow/lieferheld_s1.png';
import lieferheld_slideshow_s2 from '../images/work/slideshow/lieferheld_s2.png';
import lieferheld_slideshow_s3 from '../images/work/slideshow/lieferheld_s3.png';

import lemontbotanique_slideshow_xl1 from '../images/work/slideshow/lemontbotanique_xl1.png';
import lemontbotanique_slideshow_xl2 from '../images/work/slideshow/lemontbotanique_xl2.png';
import lemontbotanique_slideshow_xl3 from '../images/work/slideshow/lemontbotanique_xl3.png';
import lemontbotanique_slideshow_l1 from '../images/work/slideshow/lemontbotanique_l1.png';
import lemontbotanique_slideshow_l2 from '../images/work/slideshow/lemontbotanique_l2.png';
import lemontbotanique_slideshow_l3 from '../images/work/slideshow/lemontbotanique_l3.png';
import lemontbotanique_slideshow_m1 from '../images/work/slideshow/lemontbotanique_m1.png';
import lemontbotanique_slideshow_m2 from '../images/work/slideshow/lemontbotanique_m2.png';
import lemontbotanique_slideshow_m3 from '../images/work/slideshow/lemontbotanique_m3.png';
import lemontbotanique_slideshow_s1 from '../images/work/slideshow/lemontbotanique_s1.png';
import lemontbotanique_slideshow_s2 from '../images/work/slideshow/lemontbotanique_s2.png';
import lemontbotanique_slideshow_s3 from '../images/work/slideshow/lemontbotanique_s3.png';

import supper_apps_slideshow_xl1 from '../images/work/slideshow/supper_apps_xl1.png';
import supper_apps_slideshow_xl2 from '../images/work/slideshow/supper_apps_xl2.png';
import supper_apps_slideshow_xl3 from '../images/work/slideshow/supper_apps_xl3.png';
import supper_apps_slideshow_l1 from '../images/work/slideshow/supper_apps_l1.png';
import supper_apps_slideshow_l2 from '../images/work/slideshow/supper_apps_l2.png';
import supper_apps_slideshow_l3 from '../images/work/slideshow/supper_apps_l3.png';
import supper_apps_slideshow_m1 from '../images/work/slideshow/supper_apps_m1.png';
import supper_apps_slideshow_m2 from '../images/work/slideshow/supper_apps_m2.png';
import supper_apps_slideshow_m3 from '../images/work/slideshow/supper_apps_m3.png';
import supper_apps_slideshow_s1 from '../images/work/slideshow/supper_apps_s1.png';
import supper_apps_slideshow_s2 from '../images/work/slideshow/supper_apps_s2.png';
import supper_apps_slideshow_s3 from '../images/work/slideshow/supper_apps_s3.png';

import zukunf_lauft_slideshow_xl1 from '../images/work/slideshow/zukunf_lauft_xl1.png';
import zukunf_lauft_slideshow_xl2 from '../images/work/slideshow/zukunf_lauft_xl2.png';
import zukunf_lauft_slideshow_xl3 from '../images/work/slideshow/zukunf_lauft_xl3.png';
import zukunf_lauft_slideshow_l1 from '../images/work/slideshow/zukunf_lauft_l1.png';
import zukunf_lauft_slideshow_l2 from '../images/work/slideshow/zukunf_lauft_l2.png';
import zukunf_lauft_slideshow_l3 from '../images/work/slideshow/zukunf_lauft_l3.png';
import zukunf_lauft_slideshow_m1 from '../images/work/slideshow/zukunf_lauft_m1.png';
import zukunf_lauft_slideshow_m2 from '../images/work/slideshow/zukunf_lauft_m2.png';
import zukunf_lauft_slideshow_m3 from '../images/work/slideshow/zukunf_lauft_m3.png';
import zukunf_lauft_slideshow_s1 from '../images/work/slideshow/zukunf_lauft_s1.png';
import zukunf_lauft_slideshow_s2 from '../images/work/slideshow/zukunf_lauft_s2.png';
import zukunf_lauft_slideshow_s3 from '../images/work/slideshow/zukunf_lauft_s3.png';

import iam_slideshow_xl1 from '../images/work/slideshow/iam_xl1.png';
import iam_slideshow_xl2 from '../images/work/slideshow/iam_xl2.png';
import iam_slideshow_xl3 from '../images/work/slideshow/iam_xl3.png';
import iam_slideshow_l1 from '../images/work/slideshow/iam_l1.png';
import iam_slideshow_l2 from '../images/work/slideshow/iam_l2.png';
import iam_slideshow_l3 from '../images/work/slideshow/iam_l3.png';
import iam_slideshow_m1 from '../images/work/slideshow/iam_m1.png';
import iam_slideshow_m2 from '../images/work/slideshow/iam_m2.png';
import iam_slideshow_m3 from '../images/work/slideshow/iam_m3.png';
import iam_slideshow_s1 from '../images/work/slideshow/iam_s1.png';
import iam_slideshow_s2 from '../images/work/slideshow/iam_s2.png';
import iam_slideshow_s3 from '../images/work/slideshow/iam_s3.png';

import calconvert_slideshow_xl1 from '../images/work/slideshow/calconvert_xl1.png';
import calconvert_slideshow_xl2 from '../images/work/slideshow/calconvert_xl2.png';
import calconvert_slideshow_xl3 from '../images/work/slideshow/calconvert_xl3.png';
import calconvert_slideshow_l1 from '../images/work/slideshow/calconvert_l1.png';
import calconvert_slideshow_l2 from '../images/work/slideshow/calconvert_l2.png';
import calconvert_slideshow_l3 from '../images/work/slideshow/calconvert_l3.png';
import calconvert_slideshow_m1 from '../images/work/slideshow/calconvert_m1.png';
import calconvert_slideshow_m2 from '../images/work/slideshow/calconvert_m2.png';
import calconvert_slideshow_m3 from '../images/work/slideshow/calconvert_m3.png';
import calconvert_slideshow_s1 from '../images/work/slideshow/calconvert_s1.png';
import calconvert_slideshow_s2 from '../images/work/slideshow/calconvert_s2.png';
import calconvert_slideshow_s3 from '../images/work/slideshow/calconvert_s3.png';

import affligem_slideshow_xl1 from '../images/work/slideshow/affligem_xl1.png';
import affligem_slideshow_xl2 from '../images/work/slideshow/affligem_xl2.png';
import affligem_slideshow_xl3 from '../images/work/slideshow/affligem_xl3.png';
import affligem_slideshow_l1 from '../images/work/slideshow/affligem_l1.png';
import affligem_slideshow_l2 from '../images/work/slideshow/affligem_l2.png';
import affligem_slideshow_l3 from '../images/work/slideshow/affligem_l3.png';
import affligem_slideshow_m1 from '../images/work/slideshow/affligem_m1.png';
import affligem_slideshow_m2 from '../images/work/slideshow/affligem_m2.png';
import affligem_slideshow_m3 from '../images/work/slideshow/affligem_m3.png';
import affligem_slideshow_s1 from '../images/work/slideshow/affligem_s1.png';
import affligem_slideshow_s2 from '../images/work/slideshow/affligem_s2.png';
import affligem_slideshow_s3 from '../images/work/slideshow/affligem_s3.png';

import gothenburg_slideshow_xl1 from '../images/work/slideshow/gothenburg_xl1.png';
import gothenburg_slideshow_xl2 from '../images/work/slideshow/gothenburg_xl2.png';
import gothenburg_slideshow_xl3 from '../images/work/slideshow/gothenburg_xl3.png';
import gothenburg_slideshow_l1 from '../images/work/slideshow/gothenburg_l1.png';
import gothenburg_slideshow_l2 from '../images/work/slideshow/gothenburg_l2.png';
import gothenburg_slideshow_l3 from '../images/work/slideshow/gothenburg_l3.png';
import gothenburg_slideshow_m1 from '../images/work/slideshow/gothenburg_m1.png';
import gothenburg_slideshow_m2 from '../images/work/slideshow/gothenburg_m2.png';
import gothenburg_slideshow_m3 from '../images/work/slideshow/gothenburg_m3.png';
import gothenburg_slideshow_s1 from '../images/work/slideshow/gothenburg_s1.png';
import gothenburg_slideshow_s2 from '../images/work/slideshow/gothenburg_s2.png';
import gothenburg_slideshow_s3 from '../images/work/slideshow/gothenburg_s3.png';

import spooky_hill_slideshow_xl1 from '../images/work/slideshow/spooky_hill_xl1.png';
import spooky_hill_slideshow_xl2 from '../images/work/slideshow/spooky_hill_xl2.png';
import spooky_hill_slideshow_xl3 from '../images/work/slideshow/spooky_hill_xl3.png';
import spooky_hill_slideshow_l1 from '../images/work/slideshow/spooky_hill_l1.png';
import spooky_hill_slideshow_l2 from '../images/work/slideshow/spooky_hill_l2.png';
import spooky_hill_slideshow_l3 from '../images/work/slideshow/spooky_hill_l3.png';
import spooky_hill_slideshow_m1 from '../images/work/slideshow/spooky_hill_m1.png';
import spooky_hill_slideshow_m2 from '../images/work/slideshow/spooky_hill_m2.png';
import spooky_hill_slideshow_m3 from '../images/work/slideshow/spooky_hill_m3.png';
import spooky_hill_slideshow_s1 from '../images/work/slideshow/spooky_hill_s1.png';
import spooky_hill_slideshow_s2 from '../images/work/slideshow/spooky_hill_s2.png';
import spooky_hill_slideshow_s3 from '../images/work/slideshow/spooky_hill_s3.png';

import mr_mrs_smith_slideshow_xl1 from '../images/work/slideshow/mr_mrs_smith_xl1.png';
import mr_mrs_smith_slideshow_xl2 from '../images/work/slideshow/mr_mrs_smith_xl2.png';
import mr_mrs_smith_slideshow_xl3 from '../images/work/slideshow/mr_mrs_smith_xl3.png';
import mr_mrs_smith_slideshow_l1 from '../images/work/slideshow/mr_mrs_smith_l1.png';
import mr_mrs_smith_slideshow_l2 from '../images/work/slideshow/mr_mrs_smith_l2.png';
import mr_mrs_smith_slideshow_l3 from '../images/work/slideshow/mr_mrs_smith_l3.png';
import mr_mrs_smith_slideshow_m1 from '../images/work/slideshow/mr_mrs_smith_m1.png';
import mr_mrs_smith_slideshow_m2 from '../images/work/slideshow/mr_mrs_smith_m2.png';
import mr_mrs_smith_slideshow_m3 from '../images/work/slideshow/mr_mrs_smith_m3.png';
import mr_mrs_smith_slideshow_s1 from '../images/work/slideshow/mr_mrs_smith_s1.png';
import mr_mrs_smith_slideshow_s2 from '../images/work/slideshow/mr_mrs_smith_s2.png';
import mr_mrs_smith_slideshow_s3 from '../images/work/slideshow/mr_mrs_smith_s3.png';

import timer_slideshow_xl1 from '../images/work/slideshow/timer_xl1.png';
import timer_slideshow_xl2 from '../images/work/slideshow/timer_xl2.png';
import timer_slideshow_xl3 from '../images/work/slideshow/timer_xl3.png';
import timer_slideshow_l1 from '../images/work/slideshow/timer_l1.png';
import timer_slideshow_l2 from '../images/work/slideshow/timer_l2.png';
import timer_slideshow_l3 from '../images/work/slideshow/timer_l3.png';
import timer_slideshow_m1 from '../images/work/slideshow/timer_m1.png';
import timer_slideshow_m2 from '../images/work/slideshow/timer_m2.png';
import timer_slideshow_m3 from '../images/work/slideshow/timer_m3.png';
import timer_slideshow_s1 from '../images/work/slideshow/timer_s1.png';
import timer_slideshow_s2 from '../images/work/slideshow/timer_s2.png';
import timer_slideshow_s3 from '../images/work/slideshow/timer_s3.png';

import msf_slideshow_xl1 from '../images/work/slideshow/msf_xl1.png';
import msf_slideshow_xl2 from '../images/work/slideshow/msf_xl2.png';
import msf_slideshow_xl3 from '../images/work/slideshow/msf_xl3.png';
import msf_slideshow_l1 from '../images/work/slideshow/msf_l1.png';
import msf_slideshow_l2 from '../images/work/slideshow/msf_l2.png';
import msf_slideshow_l3 from '../images/work/slideshow/msf_l3.png';
import msf_slideshow_m1 from '../images/work/slideshow/msf_m1.png';
import msf_slideshow_m2 from '../images/work/slideshow/msf_m2.png';
import msf_slideshow_m3 from '../images/work/slideshow/msf_m3.png';
import msf_slideshow_s1 from '../images/work/slideshow/msf_s1.png';
import msf_slideshow_s2 from '../images/work/slideshow/msf_s2.png';
import msf_slideshow_s3 from '../images/work/slideshow/msf_s3.png';

import gasag_slideshow_xl1 from '../images/work/slideshow/gasag_xl1.png';
import gasag_slideshow_xl2 from '../images/work/slideshow/gasag_xl2.png';
import gasag_slideshow_xl3 from '../images/work/slideshow/gasag_xl3.png';
import gasag_slideshow_l1 from '../images/work/slideshow/gasag_l1.png';
import gasag_slideshow_l2 from '../images/work/slideshow/gasag_l2.png';
import gasag_slideshow_l3 from '../images/work/slideshow/gasag_l3.png';
import gasag_slideshow_m1 from '../images/work/slideshow/gasag_m1.png';
import gasag_slideshow_m2 from '../images/work/slideshow/gasag_m2.png';
import gasag_slideshow_m3 from '../images/work/slideshow/gasag_m3.png';
import gasag_slideshow_s1 from '../images/work/slideshow/gasag_s1.png';
import gasag_slideshow_s2 from '../images/work/slideshow/gasag_s2.png';
import gasag_slideshow_s3 from '../images/work/slideshow/gasag_s3.png';

import massup_slideshow_xl1 from '../images/work/slideshow/massup_xl1.png';
import massup_slideshow_xl2 from '../images/work/slideshow/massup_xl2.png';
import massup_slideshow_xl3 from '../images/work/slideshow/massup_xl3.png';
import massup_slideshow_l1 from '../images/work/slideshow/massup_l1.png';
import massup_slideshow_l2 from '../images/work/slideshow/massup_l2.png';
import massup_slideshow_l3 from '../images/work/slideshow/massup_l3.png';
import massup_slideshow_m1 from '../images/work/slideshow/massup_m1.png';
import massup_slideshow_m2 from '../images/work/slideshow/massup_m2.png';
import massup_slideshow_m3 from '../images/work/slideshow/massup_m3.png';
import massup_slideshow_s1 from '../images/work/slideshow/massup_s1.png';
import massup_slideshow_s2 from '../images/work/slideshow/massup_s2.png';
import massup_slideshow_s3 from '../images/work/slideshow/massup_s3.png';

import hmd_slideshow_xl1 from '../images/work/slideshow/hmd_xl1.png';
import hmd_slideshow_xl2 from '../images/work/slideshow/hmd_xl2.png';
import hmd_slideshow_xl3 from '../images/work/slideshow/hmd_xl3.png';
import hmd_slideshow_l1 from '../images/work/slideshow/hmd_l1.png';
import hmd_slideshow_l2 from '../images/work/slideshow/hmd_l2.png';
import hmd_slideshow_l3 from '../images/work/slideshow/hmd_l3.png';
import hmd_slideshow_m1 from '../images/work/slideshow/hmd_m1.png';
import hmd_slideshow_m2 from '../images/work/slideshow/hmd_m2.png';
import hmd_slideshow_m3 from '../images/work/slideshow/hmd_m3.png';
import hmd_slideshow_s1 from '../images/work/slideshow/hmd_s1.png';
import hmd_slideshow_s2 from '../images/work/slideshow/hmd_s2.png';
import hmd_slideshow_s3 from '../images/work/slideshow/hmd_s3.png';

import siili_slideshow_xl1 from '../images/work/slideshow/siili_xl1.png';
import siili_slideshow_xl2 from '../images/work/slideshow/siili_xl2.png';
import siili_slideshow_xl3 from '../images/work/slideshow/siili_xl3.png';
import siili_slideshow_l1 from '../images/work/slideshow/siili_l1.png';
import siili_slideshow_l2 from '../images/work/slideshow/siili_l2.png';
import siili_slideshow_l3 from '../images/work/slideshow/siili_l3.png';
import siili_slideshow_m1 from '../images/work/slideshow/siili_m1.png';
import siili_slideshow_m2 from '../images/work/slideshow/siili_m2.png';
import siili_slideshow_m3 from '../images/work/slideshow/siili_m3.png';
import siili_slideshow_s1 from '../images/work/slideshow/siili_s1.png';
import siili_slideshow_s2 from '../images/work/slideshow/siili_s2.png';
import siili_slideshow_s3 from '../images/work/slideshow/siili_s3.png';

import opentalk_slideshow_xl1 from '../images/work/slideshow/opentalk_xl1.png';
import opentalk_slideshow_xl2 from '../images/work/slideshow/opentalk_xl2.png';
import opentalk_slideshow_xl3 from '../images/work/slideshow/opentalk_xl3.png';
import opentalk_slideshow_l1 from '../images/work/slideshow/opentalk_l1.png';
import opentalk_slideshow_l2 from '../images/work/slideshow/opentalk_l2.png';
import opentalk_slideshow_l3 from '../images/work/slideshow/opentalk_l3.png';
import opentalk_slideshow_m1 from '../images/work/slideshow/opentalk_m1.png';
import opentalk_slideshow_m2 from '../images/work/slideshow/opentalk_m2.png';
import opentalk_slideshow_m3 from '../images/work/slideshow/opentalk_m3.png';
import opentalk_slideshow_s1 from '../images/work/slideshow/opentalk_s1.png';
import opentalk_slideshow_s2 from '../images/work/slideshow/opentalk_s2.png';
import opentalk_slideshow_s3 from '../images/work/slideshow/opentalk_s3.png';

import fitogram_slideshow_xl1 from '../images/work/slideshow/fitogram_xl1.png';
import fitogram_slideshow_xl2 from '../images/work/slideshow/fitogram_xl2.png';
import fitogram_slideshow_xl3 from '../images/work/slideshow/fitogram_xl3.png';
import fitogram_slideshow_l1 from '../images/work/slideshow/fitogram_l1.png';
import fitogram_slideshow_l2 from '../images/work/slideshow/fitogram_l2.png';
import fitogram_slideshow_l3 from '../images/work/slideshow/fitogram_l3.png';
import fitogram_slideshow_m1 from '../images/work/slideshow/fitogram_m1.png';
import fitogram_slideshow_m2 from '../images/work/slideshow/fitogram_m2.png';
import fitogram_slideshow_m3 from '../images/work/slideshow/fitogram_m3.png';
import fitogram_slideshow_s1 from '../images/work/slideshow/fitogram_s1.png';
import fitogram_slideshow_s2 from '../images/work/slideshow/fitogram_s2.png';
import fitogram_slideshow_s3 from '../images/work/slideshow/fitogram_s3.png';

import quieton_slideshow_xl1 from '../images/work/slideshow/quieton_xl1.png';
import quieton_slideshow_xl2 from '../images/work/slideshow/quieton_xl2.png';
import quieton_slideshow_xl3 from '../images/work/slideshow/quieton_xl3.png';
import quieton_slideshow_l1 from '../images/work/slideshow/quieton_l1.png';
import quieton_slideshow_l2 from '../images/work/slideshow/quieton_l2.png';
import quieton_slideshow_l3 from '../images/work/slideshow/quieton_l3.png';
import quieton_slideshow_m1 from '../images/work/slideshow/quieton_m1.png';
import quieton_slideshow_m2 from '../images/work/slideshow/quieton_m2.png';
import quieton_slideshow_m3 from '../images/work/slideshow/quieton_m3.png';
import quieton_slideshow_s1 from '../images/work/slideshow/quieton_s1.png';
import quieton_slideshow_s2 from '../images/work/slideshow/quieton_s2.png';
import quieton_slideshow_s3 from '../images/work/slideshow/quieton_s3.png';


const autoplaySpeed = 2500;

export const slides = [
    //----          Open Talk           -----//
    {
        id: 1,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#9bd247',
                linesOverColor: '#9bd247',
                xNormalColor: '#9bd247',
                xOverColor: '#9bd247'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": opentalk_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": opentalk_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": opentalk_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": opentalk_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": opentalk_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#9bd247',
                "className": "dialog-opentalk"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#37a2f1',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project23.name",
                    "subtitle": "pages.work.project23.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": opentalk_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": opentalk_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": opentalk_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": opentalk_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#37a2f1',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project23.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": opentalk_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": opentalk_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": opentalk_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": opentalk_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'opentalk'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_opentalk",
                                bulletActiveClass: "droxic-active-bullet-class_opentalk"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": opentalk_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          Siili           -----//
    {
        id: 2,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#ffff00',
                linesOverColor: '#ffff00',
                xNormalColor: '#ffff00',
                xOverColor: '#ffff00'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": siili_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": siili_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": siili_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": siili_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": siili_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ffffff',
                "className": "dialog-siili"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#37a2f1',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project22.name",
                    "subtitle": "pages.work.project22.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": siili_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": siili_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": siili_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": siili_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#37a2f1',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project22.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": siili_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": siili_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": siili_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": siili_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'siili'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_siili",
                                bulletActiveClass: "droxic-active-bullet-class_siili"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": siili_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          Fitogram           -----//
    {
        id: 3,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#6cbaff',
                linesOverColor: '#6cbaff',
                xNormalColor: '#6cbaff',
                xOverColor: '#6cbaff'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": fitogram_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": fitogram_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": fitogram_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": fitogram_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": fitogram_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#6cbaff',
                "className": "dialog-fitogram"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#6cbaff',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project24.name",
                    "subtitle": "pages.work.project24.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": fitogram_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": fitogram_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": fitogram_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": fitogram_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#6cbaff',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project24.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": fitogram_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": fitogram_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": fitogram_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": fitogram_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'fitogram'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_fitogram",
                                bulletActiveClass: "droxic-active-bullet-class_fitogram"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": fitogram_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          Quiet On           -----//
    {
        id: 4,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#F7F6EA',
                linesOverColor: '#F7F6EA',
                xNormalColor: '#F7F6EA',
                xOverColor: '#F7F6EA'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": quieton_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": quieton_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": quieton_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": quieton_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": quieton_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#F7F6EA',
                "className": "dialog-quieton"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#F7F6EA',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project25.name",
                    "subtitle": "pages.work.project25.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": quieton_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": quieton_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": quieton_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": quieton_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#F7F6EA',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project25.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": quieton_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": quieton_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": quieton_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": quieton_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'quieton'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_quieton",
                                bulletActiveClass: "droxic-active-bullet-class_quieton"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": quieton_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          HMD           -----//
    {
        id: 5,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#ffffff',
                linesOverColor: '#ffffff',
                xNormalColor: '#ffffff',
                xOverColor: '#ffffff'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": hmd_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": hmd_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": hmd_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": hmd_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": hmd_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ffffff',
                "className": "dialog-hmd"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#37a2f1',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project21.name",
                    "subtitle": "pages.work.project21.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": hmd_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": hmd_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": hmd_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": hmd_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#37a2f1',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project21.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": hmd_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": hmd_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": hmd_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": hmd_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'hmd'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_hmd",
                                bulletActiveClass: "droxic-active-bullet-class_hmd"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": hmd_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          MassUp           -----//
    {
        id: 6,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#dc692e',
                linesOverColor: '#dc692e',
                xNormalColor: '#dc692e',
                xOverColor: '#dc692e'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": massup_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": massup_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": massup_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": massup_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": massup_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ee342a',
                "className": "dialog-massup"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project20.name",
                    "subtitle": "pages.work.project20.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": massup_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": massup_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": massup_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": massup_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project20.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": massup_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": massup_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": massup_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": massup_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'massup'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_massup",
                                bulletActiveClass: "droxic-active-bullet-class_massup"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": massup_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          GASAG           -----//
    {
        id: 7,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#FFFFFF',
                linesOverColor: '#FFFFFF',
                xNormalColor: '#FFFFFF',
                xOverColor: '#FFFFFF'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": gasag_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": gasag_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": gasag_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": gasag_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": gasag_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ffffff',
                "className": "dialog-gasag"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project19.name",
                    "subtitle": "pages.work.project19.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gasag_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gasag_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gasag_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gasag_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project19.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gasag_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gasag_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gasag_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gasag_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'gasag'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_gasag",
                                bulletActiveClass: "droxic-active-bullet-class_gasag"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gasag_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----      Lieferheld      ----//
    {
        id: 8,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#ff0000',
                linesOverColor: '#ff0000',
                xNormalColor: '#ff0000',
                xOverColor: '#ff0000'
            }
        },
        "title": "Logo",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": lieferheld_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": lieferheld_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": lieferheld_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": lieferheld_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": lieferheld_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ff0000',
                "className": "dialog-lieferheld"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project1.name",
                    "subtitle": "pages.work.project1.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lieferheld_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lieferheld_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lieferheld_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lieferheld_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project1.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lieferheld_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lieferheld_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lieferheld_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lieferheld_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'lieferheld']
                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                clickable: true,
                                type: 'bullets',
                                bulletClass: "droxic-bullet-class_lieferheld",
                                bulletActiveClass: "droxic-active-bullet-class_lieferheld"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lieferheld_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----      ICAEW       ----//
    {
        id: 9,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#ff0000',
                linesOverColor: '#ff0000',
                xNormalColor: '#ff0000',
                xOverColor: '#ff0000'
            }
        },
        "title": "Logo",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": icaew_logo,

        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": icaew_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": icaew_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": icaew_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": icaew_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ff0000',
                "className": "dialog-icaew"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project2.name",
                    "subtitle": "pages.work.project2.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": icaew_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": icaew_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": icaew_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": icaew_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project2.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": icaew_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": icaew_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": icaew_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": icaew_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'icaew'],

                    },
                    dialogSlides: {
                        className: "swiper-container-centered",
                        sliderOptions: {
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_icaew",
                                bulletActiveClass: "droxic-active-bullet-class_icaew"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": icaew_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----      SupperApps       ----//
    {
        id: 10,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#c89e66',
                linesOverColor: '#c89e66',
                xNormalColor: '#c89e66',
                xOverColor: '#c89e66'
            }
        },
        "title": "Logo",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": supper_apps_logo,

        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": supper_apps_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": supper_apps_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": supper_apps_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": supper_apps_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#c89e66',
                "className": "dialog-supper_apps"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project3.name",
                    "subtitle": "pages.work.project3.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": supper_apps_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": supper_apps_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": supper_apps_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": supper_apps_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project3.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": supper_apps_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": supper_apps_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": supper_apps_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": supper_apps_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'supper_apps'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_supper_apps",
                                bulletActiveClass: "droxic-active-bullet-class_supper_apps"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": supper_apps_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----      IAM       ----//
    {
        id: 11,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#0eaed0',
                linesOverColor: '#0eaed0',
                xNormalColor: '#0eaed0',
                xOverColor: '#0eaed0'
            }
        },
        "title": "Logo",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": iam_logo,

        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": iam_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": iam_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": iam_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": iam_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#0eaed0',
                "className": "dialog-iam"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project4.name",
                    "subtitle": "pages.work.project4.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": iam_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": iam_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": iam_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": iam_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project4.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": iam_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": iam_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": iam_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": iam_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'iam'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_iam",
                                bulletActiveClass: "droxic-active-bullet-class_iam"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": iam_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----- Allly -----//
    {
        id:12,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#2d7ec0',
                linesOverColor: '#2d7ec0',
                xNormalColor: '#2d7ec0',
                xOverColor: '#2d7ec0'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "mobile",
        "type": PAGE_TYPE_INTERNAL,
        "logo": ally_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": ally_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": ally_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": ally_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": ally_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#2d7ec0',
                "className": "dialog-ally"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project5.name",
                    "subtitle": "pages.work.project5.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": ally_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": ally_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": ally_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": ally_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project5.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": ally_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": ally_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": ally_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": ally_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'ally'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_ally",
                                bulletActiveClass: "droxic-active-bullet-class_ally"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                id: 1,
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_xl1
                                    }
                                }
                            },
                            {
                                id: 2,
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_xl2
                                    }
                                }
                            },
                            {
                                id: 3,
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": ally_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          Mamut           -----//
    {
        id:13,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#ffffff',
                linesOverColor: '#ffffff',
                xNormalColor: '#ffffff',
                xOverColor: '#ffffff'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": mamut_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mamut_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mamut_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mamut_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mamut_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ffffff',
                "className": "dialog-mamut"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project6.name",
                    "subtitle": "pages.work.project6.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mamut_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mamut_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mamut_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mamut_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project6.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mamut_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mamut_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mamut_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mamut_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'mamut'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_mamut",
                                bulletActiveClass: "droxic-active-bullet-class_mamut"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                id: 1,
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_xl1
                                    }
                                }
                            },
                            {
                                id: 2,
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_xl2
                                    }
                                }
                            },
                            {
                                id: 3,
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mamut_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //-----     Raiffeisen Bank     -----//
    {
        id: 14,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#fffe00',
                linesOverColor: '#fffe00',
                xNormalColor: '#fffe00',
                xOverColor: '#fffe00'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": raiffeisen_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": raiffeisen_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": raiffeisen_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": raiffeisen_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": raiffeisen_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#fffe00',
                "className": "dialog-raiffeisen"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project7.name",
                    "subtitle": "pages.work.project7.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": raiffeisen_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": raiffeisen_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": raiffeisen_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": raiffeisen_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project7.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": raiffeisen_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": raiffeisen_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": raiffeisen_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": raiffeisen_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'raiffeisen'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_raiffeisen",
                                bulletActiveClass: "droxic-active-bullet-class_raiffeisen"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": raiffeisen_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //-----     Mr & Ms Smith       -----//
    {
        id: 15,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#740316',
                linesOverColor: '#740316',
                xNormalColor: '#740316',
                xOverColor: '#740316'
            }
        },
        "title": "pages.home.slides.expertiseTitle",
        "subtitle": "pages.home.slides.expertiseSubtitle",
        "category": "mobile",
        "type": PAGE_TYPE_INTERNAL,
        "logo": mr_mrs_smith_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mr_mrs_smith_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mr_mrs_smith_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mr_mrs_smith_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": mr_mrs_smith_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#740316',
                "className": "dialog-mr_mrs_smith"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project8.name",
                    "subtitle": "pages.work.project8.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mr_mrs_smith_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mr_mrs_smith_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mr_mrs_smith_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mr_mrs_smith_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project8.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mr_mrs_smith_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mr_mrs_smith_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mr_mrs_smith_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mr_mrs_smith_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'mr_mrs_smith'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_mr_mrs_smith",
                                bulletActiveClass: "droxic-active-bullet-class_mr_mrs_smith"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": mr_mrs_smith_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //-----     Le Mont Botanique       -----//
    {
        id: 16,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#ffffff',
                linesOverColor: '#ffffff',
                xNormalColor: '#ffffff',
                xOverColor: '#ffffff'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "mobile",
        "type": PAGE_TYPE_INTERNAL,
        "logo": lemontbotanique_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": lemontbotanique_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": lemontbotanique_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": lemontbotanique_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": lemontbotanique_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ffffff',
                "className": "dialog-lemontbotanique"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project9.name",
                    "subtitle": "pages.work.project9.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lemontbotanique_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lemontbotanique_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lemontbotanique_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lemontbotanique_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project9.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lemontbotanique_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lemontbotanique_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lemontbotanique_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": lemontbotanique_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'lemontbotanique'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_lemontbotanique",
                                bulletActiveClass: "droxic-active-bullet-class_lemontbotanique"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": lemontbotanique_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //-----     Bubble Nova     -----//
    {
        id: 17,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#fff564',
                linesOverColor: '#fff564',
                xNormalColor: '#fff564',
                xOverColor: '#fff564'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "mobile",
        "type": PAGE_TYPE_INTERNAL,
        "logo": bubble_nova_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": bubble_nova_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": bubble_nova_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": bubble_nova_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": bubble_nova_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#fff564',
                "className": "dialog-bubble_nova"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project10.name",
                    "subtitle": "pages.work.project10.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": bubble_nova_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": bubble_nova_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": bubble_nova_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": bubble_nova_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project10.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": bubble_nova_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": bubble_nova_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": bubble_nova_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": bubble_nova_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'bubble_nova'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_bubble_nova",
                                bulletActiveClass: "droxic-active-bullet-class_bubble_nova"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": bubble_nova_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //-----     CalConvert       -----//
    {
        id: 18,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#FFFFFF',
                linesOverColor: '#FFFFFF',
                xNormalColor: '#FFFFFF',
                xOverColor: '#FFFFFF'
            }
        },
        "title": "pages.home.slides.expertiseTitle",
        "subtitle": "pages.home.slides.expertiseSubtitle",
        "category": "mobile",
        "type": PAGE_TYPE_INTERNAL,
        "logo": calconvert_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": calconvert_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": calconvert_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": calconvert_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": calconvert_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#FFFFFF',
                "className": "dialog-calconvert"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project11.name",
                    "subtitle": "pages.work.project11.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": calconvert_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": calconvert_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": calconvert_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": calconvert_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project11.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": calconvert_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": calconvert_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": calconvert_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": calconvert_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'calconvert'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_calconvert",
                                bulletActiveClass: "droxic-active-bullet-class_calconvert"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": calconvert_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----      Timer      ----//
    {
        id: 19,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#EA4487',
                linesOverColor: '#EA4487',
                xNormalColor: '#EA4487',
                xOverColor: '#EA4487'
            }
        },
        "title": "Logo",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": timer_logo,

        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": timer_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": timer_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": timer_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": timer_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#EA4487',
                "className": "dialog-timer"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project12.name",
                    "subtitle": "pages.work.project12.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": timer_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": timer_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": timer_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": timer_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project12.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": timer_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": timer_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": timer_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": timer_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'timer'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_timer",
                                bulletActiveClass: "droxic-active-bullet-class_timer"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": timer_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----      Spooky Hill       ----//
    {
        id: 20,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#4471fe',
                linesOverColor: '#4471fe',
                xNormalColor: '#4471fe',
                xOverColor: '#4471fe'
            }
        },
        "title": "Logo",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": spooky_hill_logo,

        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": spooky_hill_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": spooky_hill_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": spooky_hill_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": spooky_hill_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#4471fe',
                "className": "dialog-spooky_hill"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project13.name",
                    "subtitle": "pages.work.project13.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": spooky_hill_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": spooky_hill_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": spooky_hill_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": spooky_hill_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project13.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": spooky_hill_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": spooky_hill_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": spooky_hill_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": spooky_hill_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'spooky_hill'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_spooky_hill",
                                bulletActiveClass: "droxic-active-bullet-class_spooky_hill"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": spooky_hill_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----      Gothenburg       ----//
    {
        id: 21,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#0091D8',
                linesOverColor: '#0091D8',
                xNormalColor: '#0091D8',
                xOverColor: '#0091D8'
            }
        },
        "title": "Logo",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": gothenburg_logo,

        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": gothenburg_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": gothenburg_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": gothenburg_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": gothenburg_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#0091D8',
                "className": "dialog-gothenburg"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project14.name",
                    "subtitle": "pages.work.project14.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gothenburg_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gothenburg_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gothenburg_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gothenburg_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project14.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gothenburg_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gothenburg_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gothenburg_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": gothenburg_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'gothenburg'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_gothenburg",
                                bulletActiveClass: "droxic-active-bullet-class_gothenburg"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": gothenburg_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----      Afligem       ----//
    {
        id: 22,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#cc3333',
                linesOverColor: '#cc3333',
                xNormalColor: '#cc3333',
                xOverColor: '#cc3333'
            }
        },
        "title": "Logo",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": affligem_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": affligem_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": affligem_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": affligem_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": affligem_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#cc3333',
                "className": "dialog-affligem"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project15.name",
                    "subtitle": "pages.work.project15.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": affligem_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": affligem_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": affligem_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": affligem_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project15.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": affligem_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": affligem_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": affligem_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": affligem_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'affligem'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_affligem",
                                bulletActiveClass: "droxic-active-bullet-class_affligem"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": affligem_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----- Zukunf Läuft -----//
    {
        id: 23,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#e4027c',
                linesOverColor: '#e4027c',
                xNormalColor: '#e4027c',
                xOverColor: '#e4027c'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "mobile",
        "type": PAGE_TYPE_INTERNAL,
        "logo": zukunf_lauft_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": zukunf_lauft_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": zukunf_lauft_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": zukunf_lauft_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": zukunf_lauft_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#e4027c',
                "className": "dialog-zukunf_lauft"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project16.name",
                    "subtitle": "pages.work.project16.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": zukunf_lauft_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": zukunf_lauft_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": zukunf_lauft_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": zukunf_lauft_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project16.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": zukunf_lauft_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": zukunf_lauft_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": zukunf_lauft_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": zukunf_lauft_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'zukunf_lauft'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_zukunf_lauft",
                                bulletActiveClass: "droxic-active-bullet-class_zukunf_lauft"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": zukunf_lauft_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          BraveZone           -----//
    {
        id: 24,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#e42037',
                linesOverColor: '#e42037',
                xNormalColor: '#e42037',
                xOverColor: '#e42037'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": brave_zone_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": brave_zone_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": brave_zone_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": brave_zone_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": brave_zone_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#e42037',
                "className": "dialog-brave_zone"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project17.name",
                    "subtitle": "pages.work.project17.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": brave_zone_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": brave_zone_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": brave_zone_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": brave_zone_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project17.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": brave_zone_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": brave_zone_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": brave_zone_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": brave_zone_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'brave_zone'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_brave_zone",
                                bulletActiveClass: "droxic-active-bullet-class_brave_zone"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": brave_zone_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },

    //----          MSF           -----//
    {
        id: 25,
        config: {
            type: 'InfoSlide',
            "buttonColor": {
                linesNormalColor: '#ee342a',
                linesOverColor: '#ee342a',
                xNormalColor: '#ee342a',
                xOverColor: '#ee342a'
            }
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "category": "web",
        "type": PAGE_TYPE_INTERNAL,
        "logo": msf_logo,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": msf_grid_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": msf_grid_xl
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": msf_grid_xl
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": msf_grid_xl
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                "mouseAnimationColor": '#ee342a',
                "className": "dialog-msf"
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work',
                        withMouseAnimation: true
                    },
                    "title": "pages.work.project18.name",
                    "subtitle": "pages.work.project18.technologies",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": msf_background_s1
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": msf_background_m1
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": msf_background_l1
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": msf_background_xl1
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: "InfoSlide",
                        buttonColor: '#ff0000',
                        className: 'slide-work--second-page',
                        textColor: '#FFFFFF'
                    },
                    "description": "pages.work.project18.description",
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": msf_background_s2
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": msf_background_m2
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": msf_background_l2
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": msf_background_xl2
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'SwiperSlide',
                        className: ['work-slide-item-v3', 'msf'],

                    },
                    dialogSlides: {
                        sliderOptions: {
                            className: "swiper-container-centered",
                            pagination: {
                                type: 'bullets',
                                clickable: true,
                                bulletClass: "droxic-bullet-class_msf",
                                bulletActiveClass: "droxic-active-bullet-class_msf"
                            },
                            slidesPerView: 1,
                            mousewheel: false,
                            direction: 'horizontal',
                            speed: 450,
                            effect: 'slide',
                            autoplay: {
                                delay: autoplaySpeed
                            }
                        },
                        slides: [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_s1
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_m1
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_l1
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_xl1
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_s2
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_m2
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_l2
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_xl2
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },

                                "background": {
                                    "small": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_s3
                                    },
                                    "medium": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_m3
                                    },
                                    "large": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_l3
                                    },
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_IMAGE,
                                        "url": msf_slideshow_xl3
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        }
    },
];
