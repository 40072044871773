// @flow
import {
  PAGE_TYPE_INTERNAL,
  PAGE_TYPE_EXTERNAL,
  BACKGROUND_TYPE_IMAGE,
  BACKGROUND_TYPE_SOLID_COLOR,
  BACKGROUND_TYPE_VIDEO
} from '../config/consts';

// MAIN SLIDE VIDE
import careers_main_video from '../videos/careers_main.mp4';

// MAIN BACKGROUNDS
// import main_s from '../images/jobs/main_s.jpg';
// import main_m from '../images/jobs/main_m.jpg';
// import main_l from '../images/jobs/main_l.jpg';
// import main_xl from '../images/jobs/main_xl.jpg';
import main2_s from '../images/jobs/main2_s.jpg';
import main2_m from '../images/jobs/main2_m.jpg';
import main2_l from '../images/jobs/main2_l.jpg';
import main2_xl from '../images/jobs/main2_xl.jpg';
import main3_s from '../images/jobs/main3_s.jpg';
import main3_m from '../images/jobs/main3_m.jpg';
import main3_l from '../images/jobs/main3_l.jpg';
import main3_xl from '../images/jobs/main3_xl.jpg';
import job_offer1_s from '../images/jobs/job_offer1_s.jpg';
import job_offer1_m from '../images/jobs/job_offer1_m.jpg';
import job_offer1_l from '../images/jobs/job_offer1_l.jpg';
import job_offer1_xl from '../images/jobs/job_offer1_xl.jpg';
import job_offer2_s from '../images/jobs/job_offer2_s.jpg';
import job_offer2_m from '../images/jobs/job_offer2_m.jpg';
import job_offer2_l from '../images/jobs/job_offer2_l.jpg';
import job_offer2_xl from '../images/jobs/job_offer2_xl.jpg';

import job_offer3_s from '../images/jobs/job_offer3_s.jpg';
import job_offer3_m from '../images/jobs/job_offer3_m.jpg';
import job_offer3_l from '../images/jobs/job_offer3_l.jpg';
import job_offer3_xl from '../images/jobs/job_offer3_xl.jpg';
import job_offer4_s from '../images/jobs/job_offer4_s.jpg';
import job_offer4_m from '../images/jobs/job_offer4_m.jpg';
import job_offer4_l from '../images/jobs/job_offer4_l.jpg';
import job_offer4_xl from '../images/jobs/job_offer4_xl.jpg';
import job_offer5_s from '../images/jobs/job_offer5_s.jpg';
import job_offer5_m from '../images/jobs/job_offer5_m.jpg';
import job_offer5_l from '../images/jobs/job_offer5_l.jpg';
import job_offer5_xl from '../images/jobs/job_offer5_xl.jpg';
import job_offer6_s from '../images/jobs/job_offer6_s.jpg';
import job_offer6_m from '../images/jobs/job_offer6_m.jpg';
import job_offer6_l from '../images/jobs/job_offer6_l.jpg';
import job_offer6_xl from '../images/jobs/job_offer6_xl.jpg';
import job_offer7_s from '../images/jobs/job_offer7_s.jpg';
import job_offer7_m from '../images/jobs/job_offer7_m.jpg';
import job_offer7_l from '../images/jobs/job_offer7_l.jpg';
import job_offer7_xl from '../images/jobs/job_offer7_xl.jpg';
import job_offer8_s from '../images/jobs/job_offer8_s.jpg';
import job_offer8_m from '../images/jobs/job_offer8_m.jpg';
import job_offer8_l from '../images/jobs/job_offer8_l.jpg';
import job_offer8_xl from '../images/jobs/job_offer8_xl.jpg';
import job_offer9_s from '../images/jobs/job_offer9_s.jpg';
import job_offer9_m from '../images/jobs/job_offer9_m.jpg';
import job_offer9_l from '../images/jobs/job_offer9_l.jpg';
import job_offer9_xl from '../images/jobs/job_offer9_xl.jpg';

// New backgrounds added on Feb 4th 2023
import job_offer10_s from '../images/jobs/job_offer10_s.jpg';
import job_offer10_m from '../images/jobs/job_offer10_m.jpg';
import job_offer10_l from '../images/jobs/job_offer10_l.jpg';
import job_offer10_xl from '../images/jobs/job_offer10_xl.jpg';
import job_offer11_s from '../images/jobs/job_offer11_s.jpg';
import job_offer11_m from '../images/jobs/job_offer11_m.jpg';
import job_offer11_l from '../images/jobs/job_offer11_l.jpg';
import job_offer11_xl from '../images/jobs/job_offer11_xl.jpg';
import job_offer12_s from '../images/jobs/job_offer12_s.jpg';
import job_offer12_m from '../images/jobs/job_offer12_m.jpg';
import job_offer12_l from '../images/jobs/job_offer12_l.jpg';
import job_offer12_xl from '../images/jobs/job_offer12_xl.jpg';
import job_offer13_s from '../images/jobs/job_offer13_s.jpg';
import job_offer13_m from '../images/jobs/job_offer13_m.jpg';
import job_offer13_l from '../images/jobs/job_offer13_l.jpg';
import job_offer13_xl from '../images/jobs/job_offer13_xl.jpg';
import job_offer14_s from '../images/jobs/job_offer14_s.jpg';
import job_offer14_m from '../images/jobs/job_offer14_m.jpg';
import job_offer14_l from '../images/jobs/job_offer14_l.jpg';
import job_offer14_xl from '../images/jobs/job_offer14_xl.jpg';
import job_offer15_s from '../images/jobs/job_offer15_s.jpg';
import job_offer15_m from '../images/jobs/job_offer15_m.jpg';
import job_offer15_l from '../images/jobs/job_offer15_l.jpg';
import job_offer15_xl from '../images/jobs/job_offer15_xl.jpg';
import job_offer16_s from '../images/jobs/job_offer16_s.jpg';
import job_offer16_m from '../images/jobs/job_offer16_m.jpg';
import job_offer16_l from '../images/jobs/job_offer16_l.jpg';
import job_offer16_xl from '../images/jobs/job_offer16_xl.jpg';
import job_offer17_s from '../images/jobs/job_offer17_s.jpg';
import job_offer17_m from '../images/jobs/job_offer17_m.jpg';
import job_offer17_l from '../images/jobs/job_offer17_l.jpg';
import job_offer17_xl from '../images/jobs/job_offer17_xl.jpg';
import job_offer18_s from '../images/jobs/job_offer18_s.jpg';
import job_offer18_m from '../images/jobs/job_offer18_m.jpg';
import job_offer18_l from '../images/jobs/job_offer18_l.jpg';
import job_offer18_xl from '../images/jobs/job_offer18_xl.jpg';
import job_offer19_s from '../images/jobs/job_offer19_s.jpg';
import job_offer19_m from '../images/jobs/job_offer19_m.jpg';
import job_offer19_l from '../images/jobs/job_offer19_l.jpg';
import job_offer19_xl from '../images/jobs/job_offer19_xl.jpg';
import job_offer20_s from '../images/jobs/job_offer20_s.jpg';
import job_offer20_m from '../images/jobs/job_offer20_m.jpg';
import job_offer20_l from '../images/jobs/job_offer20_l.jpg';
import job_offer20_xl from '../images/jobs/job_offer20_xl.jpg';



// OFFER 1 BACKGROUNDS
import title1_s from '../images/jobs/offer1/title_s.jpg';
import title1_m from '../images/jobs/offer1/title_m.jpg';
import title1_l from '../images/jobs/offer1/title_l.jpg';
import title1_xl from '../images/jobs/offer1/title_xl.jpg';
import is_this_you1_s from '../images/jobs/offer1/is_this_you_s.jpg';
import is_this_you1_m from '../images/jobs/offer1/is_this_you_m.jpg';
import is_this_you1_l from '../images/jobs/offer1/is_this_you_l.jpg';
import is_this_you1_xl from '../images/jobs/offer1/is_this_you_xl.jpg';
import your_role1_s from '../images/jobs/offer1/your_role_s.jpg';
import your_role1_m from '../images/jobs/offer1/your_role_m.jpg';
import your_role1_l from '../images/jobs/offer1/your_role_l.jpg';
import your_role1_xl from '../images/jobs/offer1/your_role_xl.jpg';
import the_opportunity1_s from '../images/jobs/offer1/the_opportunity_s.jpg';
import the_opportunity1_m from '../images/jobs/offer1/the_opportunity_m.jpg';
import the_opportunity1_l from '../images/jobs/offer1/the_opportunity_l.jpg';
import the_opportunity1_xl from '../images/jobs/offer1/the_opportunity_xl.jpg';
import join_us1_s from '../images/jobs/offer1/join_us_s.jpg';
import join_us1_m from '../images/jobs/offer1/join_us_m.jpg';
import join_us1_l from '../images/jobs/offer1/join_us_l.jpg';
import join_us1_xl from '../images/jobs/offer1/join_us_xl.jpg';

// OFFER 2 BACKGROUNDS
import title2_s from '../images/jobs/offer2/title_s.jpg';
import title2_m from '../images/jobs/offer2/title_m.jpg';
import title2_l from '../images/jobs/offer2/title_l.jpg';
import title2_xl from '../images/jobs/offer2/title_xl.jpg';
import is_this_you2_s from '../images/jobs/offer2/is_this_you_s.jpg';
import is_this_you2_m from '../images/jobs/offer2/is_this_you_m.jpg';
import is_this_you2_l from '../images/jobs/offer2/is_this_you_l.jpg';
import is_this_you2_xl from '../images/jobs/offer2/is_this_you_xl.jpg';
import your_role2_s from '../images/jobs/offer2/your_role_s.jpg';
import your_role2_m from '../images/jobs/offer2/your_role_m.jpg';
import your_role2_l from '../images/jobs/offer2/your_role_l.jpg';
import your_role2_xl from '../images/jobs/offer2/your_role_xl.jpg';
import the_opportunity2_s from '../images/jobs/offer2/the_opportunity_s.jpg';
import the_opportunity2_m from '../images/jobs/offer2/the_opportunity_m.jpg';
import the_opportunity2_l from '../images/jobs/offer2/the_opportunity_l.jpg';
import the_opportunity2_xl from '../images/jobs/offer2/the_opportunity_xl.jpg';
import join_us2_s from '../images/jobs/offer2/join_us_s.jpg';
import join_us2_m from '../images/jobs/offer2/join_us_m.jpg';
import join_us2_l from '../images/jobs/offer2/join_us_l.jpg';
import join_us2_xl from '../images/jobs/offer2/join_us_xl.jpg';




export const slides = [
    {
        id: 1,
        config: {
            type: 'InfoSlide',
            className: 'slide-careers',
            withMouseAnimation: true
        },
        "title": "pages.careers.frontPageTitle",
        "subtitle": "pages.careers.frontPageDescription",
        "type": PAGE_TYPE_EXTERNAL,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": main2_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": main2_m
            },
            "large": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": careers_main_video
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": careers_main_video
            }
        }
    },
    [
        {
            id: 2,
            config: {
                type: 'InfoSlide',
                className: ['info-slide-bgk-scale', 'slide-careers']
            },
            "title": "pages.careers.jobOffer1.title",
            "subtitle": "pages.careers.jobOffer1.location",
            "type": PAGE_TYPE_INTERNAL,
            "button": {
                "textKey": "pages.careers.detailsButtonLabel",
                "url": "/careers/job1"
            },
            "background": {
                "small": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": job_offer10_s
                },
                "medium": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": job_offer10_m
                },
                "large": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": job_offer10_l
                },
                "extraLarge": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": job_offer10_xl
                }
            },
            dialogSlides: {
                sliderOptions: {
                    slidesPerView: 1,
                    mousewheel: true,
                    direction: 'vertical',
                    speed: 450,
                    effect: 'slide',
                    scrollbar: {
                        hide: false,
                        draggable: true,
                    },
                    noSwipingClass: 'info-slide-button'
                },
                config: {
                    mouseAnimationColor: '#000000'
                },
                slides: [
                    [
                        {
                            id: 1,
                            config: {
                                type: 'InfoSlide',
                                className: ['two-column-slide', 'info-slide-reversed-order'],
                                renderSmallerFontSize: true
                            },
                            "title": "pages.careers.jobOffer1.title",
                            "subtitle": "pages.careers.jobOffer1.location",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 2,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order'
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer7_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer7_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer7_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer7_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 3,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                verticalAlignment: 'top',
                                renderSmallerFontSize: true,
                                bigSlideItem: true
                            },
                            "title": "pages.careers.jobOffer1.requiredSkillsTitle",
                            "list": [
                                "pages.careers.jobOffer1.requiredSkillsListItem1",
                                "pages.careers.jobOffer1.requiredSkillsListItem2",
                                "pages.careers.jobOffer1.requiredSkillsListItem3",
                                "pages.careers.jobOffer1.requiredSkillsListItem4",
                                "pages.careers.jobOffer1.requiredSkillsListItem5",
                                "pages.careers.jobOffer1.requiredSkillsListItem6"
                            ],
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 4,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                smallSlideItem: true
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer11_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer11_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer11_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer11_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 5,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                verticalAlignment: 'top',
                                renderSmallerFontSize: true,
                                bigSlideItem: true
                            },
                            "title": "pages.careers.jobOffer1.responsibilitiesTitle",
                            "list": [
                                "pages.careers.jobOffer1.responsibilitiesListItem1",
                                "pages.careers.jobOffer1.responsibilitiesListItem2",
                                "pages.careers.jobOffer1.responsibilitiesListItem3",
                                "pages.careers.jobOffer1.responsibilitiesListItem4"
                            ],
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 6,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                smallSlideItem: true
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer12_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer12_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer12_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer12_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 7,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                verticalAlignment: 'top',
                                renderSmallerFontSize: true,
                                bigSlideItem: true
                            },
                            "title": "pages.careers.jobOffer1.weOfferTitle",
                            "list": [
                                "pages.careers.jobOffer1.weOfferListItem1",
                                "pages.careers.jobOffer1.weOfferListItem2",
                                "pages.careers.jobOffer1.weOfferListItem3",
                                "pages.careers.jobOffer1.weOfferListItem4",
                                "pages.careers.jobOffer1.weOfferListItem5",
                                "pages.careers.jobOffer1.weOfferListItem6"
                            ],
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 8,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                smallSlideItem: true
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer13_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer13_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer13_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer13_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 9,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                verticalAlignment: 'top',
                                renderSmallerFontSize: true,
                                bigSlideItem: true
                            },
                            "title": "pages.careers.wantToJoinTitle",
                            "careersGDPR": "pages.careers.gdprText",
                            "button": {
                                "textKey": "pages.careers.applyNowButton",
                                "url": "mailto:nataliya.koseva@droxic.com?subject=Sitecore Engineer"
                            },
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 10,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                smallSlideItem: true
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer14_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer14_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer14_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer14_xl
                                }
                            }
                        }
                    ]
                ]
            }
        },
        {
            id: 3,
            config: {
                type: 'InfoSlide',
                className: ['info-slide-bgk-scale', 'slide-careers']
            },
            "title": "pages.careers.jobOffer9.title",
            "subtitle": "pages.careers.jobOffer9.location",
            "type": PAGE_TYPE_INTERNAL,
            "button": {
                "textKey": "pages.careers.detailsButtonLabel",
                "url": "/careers/job2"
            },
            "background": {
                "small": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": job_offer15_s
                },
                "medium": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": job_offer15_m
                },
                "large": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": job_offer15_l
                },
                "extraLarge": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": job_offer15_xl
                }
            },
            dialogSlides: {
                sliderOptions: {
                    slidesPerView: 1,
                    mousewheel: true,
                    direction: 'vertical',
                    speed: 450,
                    effect: 'slide',
                    scrollbar: {
                        hide: false,
                        draggable: true,
                    },
                    noSwipingClass: 'info-slide-button'
                },
                config: {
                    mouseAnimationColor: '#000000'
                },
                slides: [
                    [
                        {
                            id: 1,
                            config: {
                                type: 'InfoSlide',
                                className: ['two-column-slide', 'info-slide-reversed-order'],
                                renderSmallerFontSize: true
                            },
                            "title": "pages.careers.jobOffer9.title",
                            "subtitle": "pages.careers.jobOffer9.location",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 2,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order'
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer16_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer16_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer16_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer16_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 3,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                verticalAlignment: 'top',
                                renderSmallerFontSize: true,
                                bigSlideItem: true
                            },
                            "title": "pages.careers.jobOffer9.requiredSkillsTitle",
                            "list": [
                                "pages.careers.jobOffer9.requiredSkillsListItem1",
                                "pages.careers.jobOffer9.requiredSkillsListItem2",
                                "pages.careers.jobOffer9.requiredSkillsListItem3",
                                "pages.careers.jobOffer9.requiredSkillsListItem4",
                                "pages.careers.jobOffer9.requiredSkillsListItem5"
                            ],
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 4,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                smallSlideItem: true
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer17_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer17_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer17_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer17_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 5,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                verticalAlignment: 'top',
                                renderSmallerFontSize: true,
                                bigSlideItem: true
                            },
                            "title": "pages.careers.jobOffer9.responsibilitiesTitle",
                            "list": [
                                "pages.careers.jobOffer9.responsibilitiesListItem1",
                                "pages.careers.jobOffer9.responsibilitiesListItem2",
                                "pages.careers.jobOffer9.responsibilitiesListItem3",
                                "pages.careers.jobOffer9.responsibilitiesListItem4",
                                "pages.careers.jobOffer9.responsibilitiesListItem5",
                            ],
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 6,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                smallSlideItem: true
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer18_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer18_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer18_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer18_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 7,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                verticalAlignment: 'top',
                                renderSmallerFontSize: true,
                                bigSlideItem: true
                            },
                            "title": "pages.careers.jobOffer9.weOfferTitle",
                            "list": [
                                "pages.careers.jobOffer9.weOfferListItem1",
                                "pages.careers.jobOffer9.weOfferListItem2",
                                "pages.careers.jobOffer9.weOfferListItem3",
                                "pages.careers.jobOffer9.weOfferListItem4",
                                "pages.careers.jobOffer9.weOfferListItem5",
                                "pages.careers.jobOffer9.weOfferListItem6"
                            ],
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 8,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                smallSlideItem: true
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer19_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer19_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer19_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer19_xl
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 9,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                horizontalAlignment: 'left',
                                verticalAlignment: 'top',
                                renderSmallerFontSize: true,
                                bigSlideItem: true
                            },
                            "title": "pages.careers.wantToJoinTitle",
                            "careersGDPR": "pages.careers.gdprText",
                            "button": {
                                "textKey": "pages.careers.applyNowButton",
                                "url": "mailto:nataliya.koseva@droxic.com?subject=Umbraco Engineer"
                            },
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 10,
                            config: {
                                type: 'InfoSlide',
                                className: 'info-slide-reversed-order',
                                smallSlideItem: true
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer20_s
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer20_m
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer20_l
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": job_offer20_xl
                                }
                            }
                        }
                    ]
                ]
            }
        }
    ],
  [
      {
          id: 4,
          config: {
              type: 'InfoSlide',
              className: ['info-slide-bgk-scale', 'slide-careers']
          },
          "title": "pages.careers.jobOffer6.title",
          "subtitle": "pages.careers.jobOffer6.location",
          "type": PAGE_TYPE_INTERNAL,
          "button": {
              "textKey": "pages.careers.detailsButtonLabel",
              "url": "/careers/job3"
          },
          "background": {
              "small": {
                  "type": BACKGROUND_TYPE_IMAGE,
                  "url": job_offer2_s
              },
              "medium": {
                  "type": BACKGROUND_TYPE_IMAGE,
                  "url": job_offer2_m
              },
              "large": {
                  "type": BACKGROUND_TYPE_IMAGE,
                  "url": job_offer2_l
              },
              "extraLarge": {
                  "type": BACKGROUND_TYPE_IMAGE,
                  "url": job_offer2_xl
              }
          },
          dialogSlides: {
              sliderOptions: {
                  slidesPerView: 1,
                  mousewheel: true,
                  direction: 'vertical',
                  speed: 450,
                  effect: 'slide',
                  scrollbar: {
                      hide: false,
                      draggable: true,
                  },
                  noSwipingClass: 'info-slide-button'
              },
              config: {
                  mouseAnimationColor: '#000000'
              },
              slides: [
                  [
                      {
                          id: 1,
                          config: {
                              type: 'InfoSlide',
                              className: ['two-column-slide', 'info-slide-reversed-order'],
                              renderSmallerFontSize: true
                          },
                          "title": "pages.careers.jobOffer6.title",
                          "subtitle": "pages.careers.jobOffer6.location",
                          "background": {
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_SOLID_COLOR,
                                  "url": "#FFFFFF"
                              }
                          }
                      },
                      {
                          id: 2,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order'
                          },
                          "background": {
                              "small": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer3_s
                              },
                              "medium": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer3_m
                              },
                              "large": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer3_l
                              },
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer3_xl
                              }
                          }
                      }
                  ],
                  [
                      {
                          id: 3,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              horizontalAlignment: 'left',
                              verticalAlignment: 'top',
                              renderSmallerFontSize: true,
                              bigSlideItem: true
                          },
                          "title": "pages.careers.jobOffer6.requiredSkillsTitle",
                          "list": [
                              "pages.careers.jobOffer6.requiredSkillsListItem1",
                              "pages.careers.jobOffer6.requiredSkillsListItem2",
                              "pages.careers.jobOffer6.requiredSkillsListItem3",
                              "pages.careers.jobOffer6.requiredSkillsListItem4"
                          ],
                          "background": {
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_SOLID_COLOR,
                                  "url": "#FFFFFF"
                              }
                          }
                      },
                      {
                          id: 4,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              smallSlideItem: true
                          },
                          "background": {
                              "small": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer4_s
                              },
                              "medium": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer4_m
                              },
                              "large": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer4_l
                              },
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer4_xl
                              }
                          }
                      }
                  ],
                  [
                      {
                          id: 5,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              horizontalAlignment: 'left',
                              verticalAlignment: 'top',
                              renderSmallerFontSize: true,
                              bigSlideItem: true
                          },
                          "title": "pages.careers.jobOffer6.requiredSkillsTitle",
                          "list": [
                              "pages.careers.jobOffer6.requiredSkillsListItem5",
                              "pages.careers.jobOffer6.requiredSkillsListItem6",
                              "pages.careers.jobOffer6.requiredSkillsListItem7"
                          ],
                          "background": {
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_SOLID_COLOR,
                                  "url": "#FFFFFF"
                              }
                          }
                      },
                      {
                          id: 6,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              smallSlideItem: true
                          },
                          "background": {
                              "small": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer5_s
                              },
                              "medium": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer5_m
                              },
                              "large": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer5_l
                              },
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer5_xl
                              }
                          }
                      }
                  ],
                  [
                      {
                          id: 7,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              horizontalAlignment: 'left',
                              verticalAlignment: 'top',
                              renderSmallerFontSize: true,
                              bigSlideItem: true
                          },
                          "title": "pages.careers.jobOffer6.responsibilitiesTitle",
                          "list": [
                              "pages.careers.jobOffer6.responsibilitiesListItem1",
                              "pages.careers.jobOffer6.responsibilitiesListItem2",
                              "pages.careers.jobOffer6.responsibilitiesListItem3",
                              "pages.careers.jobOffer6.responsibilitiesListItem4",
                              "pages.careers.jobOffer6.responsibilitiesListItem5",
                          ],
                          "background": {
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_SOLID_COLOR,
                                  "url": "#FFFFFF"
                              }
                          }
                      },
                      {
                          id: 8,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              smallSlideItem: true
                          },
                          "background": {
                              "small": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer6_s
                              },
                              "medium": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer6_m
                              },
                              "large": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer6_l
                              },
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer6_xl
                              }
                          }
                      }
                  ],
                  [
                      {
                          id: 9,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              horizontalAlignment: 'left',
                              verticalAlignment: 'top',
                              renderSmallerFontSize: true,
                              bigSlideItem: true
                          },
                          "title": "pages.careers.jobOffer6.responsibilitiesTitle",
                          "list": [
                              "pages.careers.jobOffer6.responsibilitiesListItem6",
                              "pages.careers.jobOffer6.responsibilitiesListItem7",
                              "pages.careers.jobOffer6.responsibilitiesListItem8",
                              "pages.careers.jobOffer6.responsibilitiesListItem9"
                          ],
                          "background": {
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_SOLID_COLOR,
                                  "url": "#FFFFFF"
                              }
                          }
                      },
                      {
                          id: 10,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              smallSlideItem: true
                          },
                          "background": {
                              "small": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer7_s
                              },
                              "medium": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer7_m
                              },
                              "large": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer7_l
                              },
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer7_xl
                              }
                          }
                      }
                  ],
                  [
                      {
                          id: 11,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              horizontalAlignment: 'left',
                              verticalAlignment: 'top',
                              renderSmallerFontSize: true,
                              bigSlideItem: true
                          },
                          "title": "pages.careers.jobOffer6.weOfferTitle",
                          "list": [
                              "pages.careers.jobOffer6.weOfferListItem1",
                              "pages.careers.jobOffer6.weOfferListItem2",
                              "pages.careers.jobOffer6.weOfferListItem3",
                              "pages.careers.jobOffer6.weOfferListItem4",
                              "pages.careers.jobOffer6.weOfferListItem5",
                              "pages.careers.jobOffer6.weOfferListItem6"
                          ],
                          "background": {
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_SOLID_COLOR,
                                  "url": "#FFFFFF"
                              }
                          }
                      },
                      {
                          id: 12,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              smallSlideItem: true
                          },
                          "background": {
                              "small": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer8_s
                              },
                              "medium": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer8_m
                              },
                              "large": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer8_l
                              },
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer8_xl
                              }
                          }
                      }
                  ],
                  [
                      {
                          id: 13,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              horizontalAlignment: 'left',
                              verticalAlignment: 'top',
                              renderSmallerFontSize: true,
                              bigSlideItem: true
                          },
                          "title": "pages.careers.wantToJoinTitle",
                          "careersGDPR": "pages.careers.gdprText",
                          "button": {
                              "textKey": "pages.careers.applyNowButton",
                              "url": "mailto:nataliya.koseva@droxic.com?subject=Project Manager"
                          },
                          "background": {
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_SOLID_COLOR,
                                  "url": "#FFFFFF"
                              }
                          }
                      },
                      {
                          id: 14,
                          config: {
                              type: 'InfoSlide',
                              className: 'info-slide-reversed-order',
                              smallSlideItem: true
                          },
                          "background": {
                              "small": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer9_s
                              },
                              "medium": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer9_m
                              },
                              "large": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer9_l
                              },
                              "extraLarge": {
                                  "type": BACKGROUND_TYPE_IMAGE,
                                  "url": job_offer9_xl
                              }
                          }
                      }
                  ]
              ]
          }
      }
  ]
];
